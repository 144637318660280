<template>
    <div class="page">
        <PageTitle title="中奖名单"/>
        <div class="content">
            <el-form inline :model="search" class="tb-form" label-width="90px">
                <el-form-item label="赞助商：">
                    <el-select v-model="search.brand_id">
                        <el-option label="全部" :value="null"/>
                        <el-option :label="item.brandName" :value="item.id" v-for="item in brandList"
                                   :key="item.id"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="昵称：">
                    <el-input v-model="search.nick_name"></el-input>
                </el-form-item>
                <el-form-item label="手机号：">
                    <el-input v-model="search.phone"/>
                </el-form-item>
                <el-form-item label="物流单号：">
                    <el-input v-model="search.delivery_sn"/>
                </el-form-item>
                <el-form-item label="状态：">
                    <el-select v-model="search.status">
                        <el-option label="全部" :value="null"/>
                        <el-option label="未领取" :value="1"/>
                        <el-option label="已领取未发放" :value="2"/>
                        <el-option label="已领取已发放" :value="3"/>
                    </el-select>
                </el-form-item>
                <el-form-item label="中奖商品：">
                    <el-input v-model="search.award_name"/>
                </el-form-item>
                <el-form-item label="物流公司：">
                    <el-input v-model="search.delivery_company"/>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="onSearch">查询</el-button>
                    <el-button @click="resetParams">重置</el-button>
                </el-form-item>
            </el-form>
            <div class="tablebox">
                <el-table
                        :data="tableData"
                        class="tb-table">
                    <el-table-column label="赞助商" prop="brand_name"></el-table-column>
                    <el-table-column label="昵称" prop="nick_name"></el-table-column>
                    <el-table-column label="中奖码" prop="sn"></el-table-column>
                    <el-table-column label="手机号" prop="phone"></el-table-column>
                    <el-table-column label="中奖商品">
                        <template slot-scope="scope">
                            <el-popover
                                    width="320"
                                    placement="top"
                                    trigger="hover"
                                    :content="scope.row.gift_name">
                                <div slot="reference" class="ellipsis-one">{{scope.row.gift_name}}</div>
                            </el-popover>
                        </template>
                    </el-table-column>
                    <el-table-column label="状态" prop="status">
                        <template slot-scope="scope">
                            <span v-if="scope.row.status===1">未领取</span>
                            <span v-else-if="scope.row.status===2">已领取未发放</span>
                            <span v-else-if="scope.row.status===3">已领取已发放</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="中奖时间" prop="open_time"></el-table-column>
                    <el-table-column label="收获地址" prop="address"></el-table-column>
                    <el-table-column label="联系方式" prop="contact"></el-table-column>
                    <el-table-column label="物流公司" prop="delivery_company"></el-table-column>
                    <el-table-column label="物流单号" prop="delivery_sn"></el-table-column>
                    <el-table-column label="操作" width="60px">
                        <template slot-scope="scope">
                            <el-button v-if="scope.row.status===2" type="text"
                                       @click="showAddDeliveryDialog(scope.row)">发货
                            </el-button>
                        </template>
                    </el-table-column>

                </el-table>
                <el-pagination
                        background
                        class="tb-pagination"
                        layout="prev, pager, next"
                        :current-page.sync="page.no + 1"
                        :page-size="page.size"
                        :total="page.total"
                        @current-change="pageChange"
                />
            </div>
        </div>
        <el-dialog title="录入物流信息"
                   :visible.sync="addDeliveryVisible"
                   width="488px">

            <el-form label-position="left">
                <el-form-item label="物流公司：">
                    <el-input v-model="addDeliveryInfo.delivery_company" style="width: 272px"></el-input>
                </el-form-item>
                <el-form-item label="物流单号：">
                    <el-input v-model="addDeliveryInfo.delivery_sn" style="width: 272px"/>
                </el-form-item>
                <el-form-item label="物流状态：">
                    未发货
                </el-form-item>
                <el-form-item>
                    <template>
                        <el-button type="primary" @click="requestAddDeliveryInfo">确认发货</el-button>
                        <el-button @click="cancelAddDelivery">取消</el-button>
                    </template>
                </el-form-item>
            </el-form>
        </el-dialog>
    </div>
</template>

<script>
    import PageTitle from '@/components/PageTitle'

    export default {
        name: "LotteryLucky",
        components: {
            PageTitle
        },
        mounted() {
            this.getBrandList()
            this.getList()
        },
        data() {
            return {
                search: {
                    brand_id: null,
                    nick_name: '',
                    phone: '',
                    delivery_sn: '',
                    status: null,
                    award_name: null,
                    delivery_company: null,
                },
                brandList: [],
                tableData: [],
                page: {
                    no: 0,
                    size: 10,
                    total: 0
                },
                addDeliveryInfo: {
                    id: null,
                    delivery_company: null,
                    delivery_sn: null
                },
                addDeliveryVisible: false
            }
        },
        methods: {
            async getList() {
                let {no, size} = this.page
                let params = {
                    page_size: size,
                    page_no: no,
                    ...this.search
                }
                const url = 'admin/lottery/lucky/list'
                const data = await this.$https.get(url, {params})
                if (!data) return
                this.page.total = data.total_count
                this.tableData = data.list
            },
            async getBrandList() {
                const url = "admin/home/brand/list"
                const data = await this.$https.get(url)
                if (data == null) return
                this.brandList = data
            },
            onSearch() {
                this.page.no = 0
                this.getList()
            },
            pageChange(no) {
                this.page.no = no - 1
                this.getList()
            },
            showAddDeliveryDialog(item) {
                this.addDeliveryVisible = true
                this.addDeliveryInfo.id = item.id
            },
            async requestAddDeliveryInfo() {
                let params = this.addDeliveryInfo
                const url = "admin/lottery/award/delivery"
                const data = await this.$https.post(url, params)
                if (data) {
                    this.cancelAddDelivery()
                    await this.getList()
                }
            },
            cancelAddDelivery() {
                this.addDeliveryVisible = false
                this.addDeliveryInfo.delivery_company = null
                this.addDeliveryInfo.delivery_sn = null
            },
            resetParams() {
                this.search = {
                    brand_id: null,
                    nick_name: '',
                    phone: '',
                    delivery_sn: '',
                    status: null,
                    award_name: null,
                    delivery_company: null,
                }
            }
        }
    }
</script>

<style scoped lang="scss">
    .page {
        height: 100%;
        display: flex;
        flex-direction: column;
    }
</style>