<template>
  <div class="page-title" :class="{'border': border}">
    <el-breadcrumb class="breadcrumb" separator="/" v-if="routes.length > 1">
      <el-breadcrumb-item
        :to="item.link && item.realLink !== currentLink && { path: `/${item.link}` }"
        v-for="(item, index) in routes"
        :key="index"
      >
      {{item.name}}
      </el-breadcrumb-item>
    </el-breadcrumb>
    <h1 class="title">{{title}}</h1>
  </div>
</template>

<script>
export default {
  name: 'PageTitle',
  props: {
    routes: {
      type: Array,
      default: () => {
        return []
      }
    },
    title: String,
    border: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    currentLink() {
      let path = this.$route.path
      if (path.indexOf('/') === 0) path = path.substring(1)
      return path
    }
  }
}
</script>

<style scoped lang="scss">
.page-title {
  padding: 16px 32px;
  background-color: #fff;
  z-index: 3;
  box-sizing: border-box;
  .breadcrumb {
    margin-bottom: 16px;
  }
  .title {
    margin: 0;
    padding: 0;
    font-size: 20px;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.85);
  }
}
.border {
  border-bottom: 1px solid #E9E9E9;
}
</style>
